<template>
    <div>
        <q-page class="flex flex-center row" padding>
            <div class="col-12" style="display: flex; justify-content: center;">
                <h4>
                    IRLs à venir
                </h4>
            </div>
            <q-card class="col-10" v-if="evt">
                <q-dialog v-model="details_dialog" @show="on_details_modal_show" persistent>
                <q-card style="width: 850px; max-width: 80vw;">
                    <q-card-section class="row items-center q-pb-none">
                        <div class="text-h6">Liste des inscrits ({{evt.attendees_number}})</div>
                        <q-space />
                        <q-btn icon="close" flat round dense v-close-popup />
                    </q-card-section>

                    <q-card-section>
                        <div class="q-pa-md row items-start q-gutter-md">
                            <q-card v-for="registration in registrations" flat bordered class="col-auto" style="min-width: 220px">
                                <div class="q-pa-sm text-bold">
                                    {{ registration.user }}
                                </div>
                                <q-separator/>
                                <div class="q-pa-sm">
                                    Tier de prix : {{ registration.price_category.name }}<br>
                                    Suppléments: 
                                    <ul v-if="registration.extras.length">
                                        <li v-for="extra in registration.extras">{{ extra.name }}</li>
                                    </ul>
                                </div>
                            </q-card>
                        </div>
                    </q-card-section>
                </q-card>
                </q-dialog>
                <q-card-section class="bg-primary text-white flex">
                    <div class="text-h6">{{ evt.title }}</div>
                    <q-space />
                    <q-btn class="q-mr-sm" v-if="user.is_admin" @click="details_dialog = true" color="secondary">Liste Inscrits</q-btn>
                    <q-btn color="dark"> {{ evt.remaining_slots }} Places restantes</q-btn>
                </q-card-section>
                <q-card-section>
                    <div> Date de début : <q-badge>{{ evt.start_time_formatted }}</q-badge> </div>
                    <div> Date de fin : <q-badge>{{ evt.end_time_formatted }}</q-badge> </div>
                    <div> Adresse : {{ evt.address }} </div>
                </q-card-section>
                <q-separator />
                <q-card-section v-html="evt.description"></q-card-section>
                <q-separator />
                <q-card-section class="row items-center q-gutter-md">
                    <q-card v-for="price in evt.prices" @click="price_click(price)" class="col cursor-pointer" q-hoverable v-ripple>
                        <q-card-section class="bg-dark text-white">
                            <strong>{{ price.name }}</strong>
                        </q-card-section>
                        <q-card-section v-html="price.description" style="height: 250px;" />
                        <q-separator />
                        <q-card-section class="text-center">
                            <strong>{{ price.price }}+ €</strong>
                        </q-card-section>
                    </q-card>
                </q-card-section>
                <q-separator />
                <q-card-section v-if="user_registered">
                    <div class="bg-positive text-white q-pa-sm">
                        <q-icon name="info" style="font-size: 1.5rem;" />
                        Vous êtes inscrit à cet événement !
                    </div>
                    <template v-for="extra in evt.extras">
                        <EventExtraDisplay :extra="extra" v-if="!my_registration.extras.includes(extra)" @update="update_total_price" />
                    </template>
                    <template v-if="evt.extras.length != my_registration.extras.length">
                        <div class="q-mt-md">
                            <StripePayment :amount="price_to_pay" :description="evt.title" ref="stripe" deduce-fees/>
                        </div>
                        <div class="text-right q-mt-md">
                            <q-btn :disabled="selected_price > 0" @click="pay_extras" class="bg-positive text-white" label="Payer suppléments" />
                        </div>
                    </template>
                </q-card-section>
                <q-card-section v-else-if="!evt.registration_opened && !can_pre_register">
                    <div class="bg-light-blue text-white q-pa-sm">
                        <q-icon name="info" style="font-size: 1.5rem;" />
                        Les inscriptions pour cet évènement ne sont pas encore ouvertes! Si vous avez un code de pré-inscription, merci de le saisir ci-dessous
                    </div>
                    <div class="row vertical-middle">
                        <div class="col-6">
                            <q-input v-model="pre_register_code" label="Code de pré-inscription" dense/>
                        </div>
                        <div class="col q-ml-lg q-pt-sm">
                            <q-btn @click="check_preregistration">Valider</q-btn>
                        </div>
                    </div>
                </q-card-section>
                <q-card-section v-else>
                    <div v-if="evt.remaining_slots == 0">
                        <div class="bg-negative text-white q-pa-sm">
                            <q-icon name="warning" style="font-size: 1.5rem;" />
                            Cet évément est complet ! Les inscriptions sont fermées.
                        </div>
                    </div>
                    <div v-else>
                        <div class="bg-light-blue text-white q-pa-sm">
                            <q-icon name="info" style="font-size: 1.5rem;" />
                            Le montant à payer est libre et à choisir en fonction de vos moyens. Il ne peut, cependant, être inférieur au prix du forfait minimum.
                        </div>
                        <q-input outlined class="q-mt-md" label="Montant à payer" type="number" :rules="[val => val >= min_price || 'Le montant payé ne peut être inférieur au prix du forfait minimum']" v-model="selected_price"/>
                        <template v-if="evt.extras.length">
                            <div class="text-h6">Suppléments</div>
                            <div class="row">
                                <q-list class="col-6">
                                    <template v-for="extra in evt.extras">
                                        <EventExtraDisplay :extra="extra" @update="update_total_price" />
                                    </template>
                                </q-list>
                            </div>
                        </template>

                        <div class="text-h6">Informations Personnelles</div>
                        <em>Ces informations ne sont pas obligatoires mais elles permettront au comité des fêtes d'améliorer l'organisation des événements. Elles seront sauvegardées sur votre profil et seront réutilisées d'une année à l'autre.</em>
                        <div class="row">
                            <div class="col-6">
                                <div><q-checkbox v-model="user.is_snoring" label="Je me considère comme ronfleur"/></div>
                                <div><q-checkbox v-model="user.snoring_tolerant" label="Je tolère un ronfleur dans ma chambre"/></div>
                            </div>
                            <div class="col-6">
                                <div><q-input v-model="user.shoe_size" label="Pointure de chaussures" type="number" sticky-label dense/></div>
                                <div><q-input v-model="user.tshirt_size" label="Taille de T-Shirt (ex: XL)" sicky-label dense/></div>
                            </div>
                        </div>

                        <hr>

                        <div class="q-mt-md">
                            <StripePayment :amount="price_to_pay" :description="evt.title" ref="stripe" deduce-fees authorize3Times/>
                        </div>
                        <div class="text-right q-mt-md">
                            <q-btn :disabled="selected_price < min_price" @click="pay" class="bg-positive text-white" label="Payer et valider mon inscription" />
                        </div>
                    </div>
                </q-card-section>
                <q-inner-loading :showing="is_loading">
                    <q-spinner-gears size="50px" color="primary" />
                </q-inner-loading>
            </q-card>
        </q-page>
    </div>
</template>


<script>
import { mapState, useStore } from "vuex";
import EventModel from "@/models/event/event.js";
import EventRegistration from "@/models/event/eventregistration.js";
import { useRoute } from "vue-router";
import StripePayment from "@/components/StripePayment";
import EventExtraDisplay from "@/components/EventExtraDisplay";
import Api, { CrudFilter } from "@/utils/api.js";

export default {
    name: "EventDetail",
    components: {
        StripePayment,
        EventExtraDisplay
    },
    computed: mapState(["user"]),
    data () {
        return {
            selected_price: this.min_price,
            price_to_pay: this.min_price,
            is_loading: false,
            can_pre_register: false,
            pre_register_code: "",
            details_dialog: false,
            registrations: []
        };
    },
    watch: {
        selected_price: "update_total_price"
    },
    async setup () {
        const store = useStore();
        const route = useRoute();
        
        await store.state.userChecked;

        let my_registration;
            
        const event_id = route.params.event_id;
        let evt = await EventModel.read(event_id, true);
        let min_price = Infinity;
        let user_registered = store.state.user.events.includes(evt);
        
        if (user_registered) {
            my_registration = await EventRegistration.get([
                new CrudFilter("user", "eq", store.state.user),
                new CrudFilter("event", "eq", event_id)
            ]);
            min_price = 0;
        } else {
            for (let price of evt.prices) {
                min_price = Math.min(price.price, min_price);    
            }
        }

        for (let extra of evt.extras) {
            extra._selected = false;
        }

        return {
            evt,
            min_price,
            user_registered,
            my_registration
        };
    },
    methods: {
        price_click (price) {
            this.selected_price = price.price;
        },
        update_total_price () {
            let extras_price = 0;

            for (let extra of this.evt.extras) {
                if (!extra._selected) continue;
                extras_price += extra.price;
            }

            this.price_to_pay = parseFloat(this.selected_price) + extras_price;

        },
        async check_preregistration () {
            this.is_loading = true;
            this.can_pre_register = await Api.post({
                handler: "event.check_preregister_code",
                args: {
                    event: this.evt,
                    code: this.pre_register_code
                }
            });
            if (!this.can_pre_register) {
                this.$q.notify({
                    type: "negative",
                    message: "Code de pré-inscription incorrect!"
                });
            }
            this.is_loading = false;
        },
        async pay () {
            this.is_loading = true;
            let selected_extras = this.evt.extras.filter(evt => evt._selected);

            try {
                let payment_resp = await this.$refs.stripe.pay();
                this.my_registration = await Api.post({
                    handler: "event.confirm_payment",
                    args: {
                        ...payment_resp,
                        event: this.evt,
                        extras: selected_extras
                    }
                });
                await this.evt.read();
                await this.user.update();
                this.user_registered = true;
                this.$q.notify({
                    type: "positive",
                    message: "Félicitations ! Votre inscription à l'événement a bien été enregistrée !"
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.is_loading = false;
            }
        },
        async on_details_modal_show () {
            this.registrations = await EventRegistration.filter([
                new CrudFilter("event", "eq", this.evt)
            ], true)
        },
        async pay_extras () {
            this.is_loading = true;
            let selected_extras = this.evt.extras.filter(evt => evt._selected);
            try {
                let payment = await this.$refs.stripe.pay();
                await Api.post({
                    handler: "event.pay_extras",
                    args: {
                        payment,
                        registration: this.my_registration,
                        extras: selected_extras
                    }
                });
                this.$q.notify({
                    type: "positive",
                    message: "Félicitations ! Le paiement de vos suppléments a bien été pris en compte !"
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.is_loading = false;
            }
        }
    }
}
</script>
